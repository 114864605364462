<script>
export default {
  name: 'CommonCopy',
};
</script>

<template>
  <div class="common common--copy">
    <div class="body-medium">Копирайт</div>

    <div class="common__body">
      <span class="displace">© 2023 UX-UI.PRO™</span>
      <span class="displace">Максим Ефремов</span>
    </div>
  </div>
</template>
