<script>
import imagesData from '../data/imagesData';

export default {
  name: 'AppImage',
  props: {
    file: {
      type: String,
      required: true,
    },
  },
  setup() {
    const srcsetAvif = (file) => {
      if (imagesData[file]?.source?.avif) {
        return Object.entries(imagesData[file]?.source?.avif)
          .map(([key, value]) => `${value} ${key}w`)
          .join(', ');
      }
      return '';
    };

    const srcsetWebp = (file) => {
      if (imagesData[file]?.source?.webp) {
        return Object.entries(imagesData[file]?.source?.webp)
          .map(([key, value]) => `${value} ${key}w`)
          .join(', ');
      }
      return '';
    };

    const srcsetJpeg = (file) => {
      if (imagesData[file]?.source?.jpeg) {
        return Object.entries(imagesData[file]?.source?.jpeg)
          .map(([key, value]) => `${value} ${key}w`)
          .join(', ');
      }
      return '';
    };

    return {
      imagesData,
      srcsetAvif,
      srcsetWebp,
      srcsetJpeg,
    };
  },
};
</script>

<template>
  <div class="image-container">
    <picture
      v-if="imagesData[file]"
      :style="
        imagesData[file].background
          ? `background-image: url(${imagesData[file].background});`
          : ''
      "
    >
      <source
        type="image/avif"
        :srcset="srcsetAvif(file)"
        :sizes="imagesData[file].source.sizes"
        v-if="imagesData[file]?.source?.avif"
      />
      <source
        type="image/webp"
        :srcset="srcsetWebp(file)"
        :sizes="imagesData[file].source.sizes"
        v-if="imagesData[file]?.source?.webp"
      />
      <source
        type="image/jpeg"
        :srcset="srcsetJpeg(file)"
        :sizes="imagesData[file].source.sizes"
        v-if="imagesData[file]?.source?.jpeg"
      />
      <img
        v-if="imagesData[file]?.img"
        :src="imagesData[file]?.img?.src"
        :width="imagesData[file]?.img?.width"
        :height="imagesData[file]?.img?.height"
        :loading="imagesData[file]?.img?.loading"
        :decoding="imagesData[file]?.img?.decoding"
        :alt="imagesData[file]?.img?.alt"
        data-sampler="planeTexture"
      />
    </picture>
  </div>
</template>

<style lang="scss" scoped>
.image-container {
  width: 100%;
  height: 100%;

  picture {
    display: flex;
    width: 100%;
    height: 100%;
    background-position: 50%;
    background-size: cover;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &[data-parallax] {
    position: relative;
    overflow: hidden;
  }
}
</style>
