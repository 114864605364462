<script>
import { onMounted, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import transitions from './router/transitions';
import updateMetadata from './utils/updateMetadata';
import bodyClass from './utils/bodyClass';
import scrolling from './utils/lenis';
import useMedia from './store/media';
import useLoading from './store/loading';
import LayoutApp from './layout/LayoutApp.vue';

export default {
  name: 'App',
  components: {
    LayoutApp,
  },
  setup() {
    const { router, media, loading } = {
      router: useRouter(),
      media: useMedia(),
      loading: useLoading(),
    };

    transitions(); // Анимации сопровождающие переходы по маршрутам
    bodyClass(); // Стилизующий класс для каждой стрницы

    onMounted(() => {
      updateMetadata(router); // Мета тайтлы и дескрипшоны

      media.initTrigger(); // Отслеживание изменений устройств ввода и размера окна браузера
    });

    watchEffect(() => { // Ленис
      if (media.touch) {
        scrolling.smooth.create();
      } else {
        scrolling.smooth.destroy();
      }
    });

    return {
      loading,
    };
  },
};
</script>

<template>
  <LayoutApp>
    <routerView></routerView>
  </LayoutApp>
</template>
