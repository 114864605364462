<script>
import CommonStatus from '../components/CommonStatus.vue';
import CommonNav from '../components/CommonNav.vue';
import CommonConnect from '../components/CommonConnect.vue';
import CommonCopy from '../components/CommonCopy.vue';
import WidgetHeading from '../components/WidgetHeading.vue';

export default {
  name: 'LayoutFooter',
  components: {
    WidgetHeading,
    CommonConnect,
    CommonNav,
    CommonStatus,
    CommonCopy,
  },
};
</script>

<template>
  <footer
    class="footer"
    data-cursor-style="none"
  >
    <WidgetHeading title="Футер">
      <div class="footer-commons">
        <div class="footer-slogan">
          <div class="title-large">Давайте создадим что-то классное! Я всегда открыт для новых проектов и&nbsp;связей.</div>
        </div>
        <CommonNav />
        <CommonConnect />
        <CommonStatus />
        <CommonCopy />
      </div>
    </WidgetHeading>
  </footer>
</template>

<style lang="scss" scoped>
@import "../assets/scss/base/mixins";

.footer-commons {
  display: grid;
  grid-template: auto / 1fr 1fr;
  gap: calc(var(--glob-gap) * 1.5) var(--glob-gap);

  @include min-width(tablet-portrait) {
    grid-template: 1fr 1fr / calc(50% - (var(--glob-gap) / 2)) 1fr 1fr;
    gap: calc(var(--glob-gap) * 2) var(--glob-gap);
    margin-bottom: calc(var(--vertical-offset) * -0.75);
    padding-top: calc(var(--vertical-offset) / 3);
  }

  .footer-slogan {
    grid-row: 1 / 2;
    grid-column: 1 / 3;

    @include max-width(tablet-portrait) {
      align-self: start;
      padding-bottom: var(--glob-gap);
      @include border-side(bottom);
    }

    @include min-width(tablet-portrait) {
      grid-row: 1 / 3;
      grid-column: 1 / 2;
    }

    .title-large {
      max-width: 330px;
      text-transform: none;
      margin: 0;
      line-height: 1.5;
    }
  }

  .common--menu {
    grid-row: 2 / 3;
    grid-column: 2 / 3;

    @include min-width(tablet-portrait) {
      grid-row: 1 / 2;
      grid-column: 2 / 3;
    }
  }

  .common--connect {
    grid-row: 3 / 4;
    grid-column: 2 / 3;

    @include min-width(tablet-portrait) {
      grid-row: 1 / 2;
      grid-column: 3 / 4;
    }
  }

  .common--status {
    grid-row: 3 / 4;
    grid-column: 1 / 2;

    @include min-width(tablet-portrait) {
      grid-row: 2 / 3;
      grid-column: 2 / 3;
    }
  }

  .common--copy {
    grid-row: 2 / 3;
    grid-column: 1 / 2;

    @include min-width(tablet-portrait) {
      grid-row: 2 / 3;
      grid-column: 3 / 4;
    }
  }
}
</style>
