<script setup>
import { onMounted, ref } from 'vue';
import Iconly from 'iconly';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
});

const iconName = ref('');

onMounted(() => {
  const iconly = new Iconly({
    file: './sprite.svg',
    version: '1.0',
    debug: true,
  });

  iconly.init().then(() => {
    iconName.value = props.name;
  });
});
</script>

<template>
  <svg class="svg-icon" v-if="iconName">
    <use :href="`#${iconName}`"></use>
  </svg>
</template>

<style scoped>
.svg-icon {
    vertical-align: top;
    display: inline-block;
    width: 24px;
    height: 24px;
}
</style>
