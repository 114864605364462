<script>
import {
  onBeforeUnmount, onMounted, ref, watch, nextTick,
} from 'vue';
import useMedia from '../store/media';
import CursorBlob from '../utils/cursorBlob';
import gsap from 'gsap';
CursorBlob.registerGSAP(gsap);

export default {
  name: 'CursorBlobComponent',
  setup() {
    const { media } = {
      media: useMedia(),
    };

    const cursorRef = ref();
    const cursorRimRef = ref();
    const cursorDotRef = ref();

    let cursorBlob = null;

    const initCursorBlob = () => {
      cursorBlob = new CursorBlob({
        cursorEl: cursorRef.value,
        cursorRimEl: cursorRimRef.value,
        cursorDotEl: cursorDotRef.value,
      });
    };

    const destroyCursorBlob = () => {
      if (cursorBlob) {
        cursorBlob.destroy();
        cursorBlob = null;
      }
    };

    onMounted(() => {
      if (media.touch) {
        initCursorBlob();
      }
    });

    onBeforeUnmount(() => {
      destroyCursorBlob();
    });

    watch(() => media.touch, async (visible) => {
      await nextTick();

      if (visible) {
        initCursorBlob();
      } else {
        destroyCursorBlob();
      }
    });

    return {
      cursorRef,
      cursorRimRef,
      cursorDotRef,
      media,
    };
  },
};
</script>

<template lang="html">
  <div class="cursor" ref="cursorRef" v-if="media.touch">
    <div class="cursor__rim" ref="cursorRimRef"></div>
    <div class="cursor__dot" ref="cursorDotRef"></div>
  </div>
</template>

<style lang="scss" scoped>
.cursor {
  --size-blob: 50px;
  --size-border: 2px;

  //--color: hsla(180deg 51% 50% / 100%);
  --color: white;
  --background: transparent;

  pointer-events: none;
  user-select: none;
  mix-blend-mode: exclusion;
  position: fixed;
  z-index: var(--z-index-cursor-blob);
  opacity: 0;
  transition: opacity 2s 0.5s;

  &__rim {
    will-change: transform;
    pointer-events: none;
    user-select: none;
    position: fixed;
    inset: 0;
    width: var(--size-blob);
    height: var(--size-blob);

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      box-shadow: 0 0 0 var(--size-border) inset var(--color);
      background-color: var(--background);
      border-radius: 100%;
      transform: scale(var(--scale, 1));
      filter: blur(var(--blur, 0));
      transition:
              box-shadow 325ms ease,
              transform 325ms ease,
              background-color 325ms ease;
    }
  }

  &__dot {
    will-change: transform;
    pointer-events: none;
    user-select: none;
    position: fixed;
    inset: 0;
    z-index: var(--z-index-cursor-blob);
    width: var(--size-blob);
    height: var(--size-blob);
    display: grid;
    place-content: center;

    &::after {
      display: block;
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 100%;
      color: transparent;
      background-color: var(--color);
      transition:
              box-shadow 325ms ease,
              transform 325ms ease,
              color 325ms ease;
    }
  }

  body.page-loaded & {
    opacity: 1;
  }

  &--headline-large {
    --color: transparent;
    --background: white;
    --scale: 3;
  }

  &--flow {
    --color: transparent;
    --background: black;
    --scale: 3;

    mix-blend-mode: unset;

    .cursor__rim {
      &::after {
        box-shadow: 0 0 0 0.5px white;
      }
    }

    .cursor__dot {
      &::after {
        width: 100%;
        height: 100%;
        inset: 0;
        margin: auto;
        content: 'Подробнее';
        white-space: nowrap;
        color: white;
        font-weight: 500;
        font-size: 1.4em;
      }
    }
  }

  &--aside {
    --color: transparent;
    --background: white;
  }

  &--image-one {
    --color: transparent;
    --background: white;
    --scale: 2;
    --blur: 7px;
  }

  &--contact-form {
    --color: transparent;
    --background: white;
    --scale: 0.2;
  }

  &--none {
    --color: transparent;
    --background: transparent;
  }
}
</style>
