<script>
import {
  onMounted, onBeforeUnmount, ref,
} from 'vue';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import scrolling from '../utils/lenis';
import VerticalLoop from '../utils/verticalLoop';
import AsideItem from '../components/AsideItem.vue';

gsap.registerPlugin(ScrollTrigger);
VerticalLoop.registerGSAP(gsap, ScrollTrigger);

export default {
  name: 'LayoutAside',
  components: {
    AsideItem,
  },
  setup() {
    const verticalLoopRef = ref();

    let verticalLoop = null;

    const initVerticalLoop = () => {
      verticalLoop = new VerticalLoop({
        loopEl: verticalLoopRef.value,
      });

      verticalLoop.init();
    };

    const destroyVerticalLoop = () => {
      if (verticalLoop) {
        verticalLoop.destroy();
        verticalLoop = null;
      }
    };

    const handleMouseEnter = () => {
      scrolling.smooth.stop();
    };

    const handleMouseLeave = () => {
      scrolling.smooth.start();
    };

    onMounted(() => {
      initVerticalLoop();
    });

    onBeforeUnmount(() => {
      destroyVerticalLoop();
    });

    return {
      verticalLoopRef,
      handleMouseEnter,
      handleMouseLeave,
    };
  },
};
</script>

<template>
  <aside
    class="aside"
    data-lenis-prevent
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
    data-cursor-style="aside"
  >
    <div class="vertical-loop" ref="verticalLoopRef">
      <AsideItem
        class="vl-items"
        link="/project/proxima"
        file="aside_proxima_01"
        project="Proxima"
      />

      <AsideItem
        class="vl-items"
        link="/project/robotech"
        file="aside_robotech_01"
        project="Robotech"
      />

      <AsideItem
        class="vl-items"
        link="/project/btn-kit"
        file="aside_btnkit_01"
        project="btn-kit"
      />

      <AsideItem
        class="vl-items"
        link="/project/flick"
        file="aside_flick_01"
        project="Flick"
      />

      <AsideItem
        class="vl-items"
        link="/project/photoe"
        file="aside_photoe_01"
        project="Photoe"
      />

      <AsideItem
        class="vl-items"
        link="/project/mariza"
        file="aside_mariza_01"
        project="MARIZA"
      />
    </div>
  </aside>
</template>

<style lang="scss" scoped>
@import "../assets/scss/base/mixins";

.aside {
  @include component-loaded;

  pointer-events: none;
  position: fixed;
  inset: 0 auto 0 0;
  margin: auto;
  width: 100vw;

  .vertical-loop {
    pointer-events: none;
    position: absolute;
    top: 0; right: 0;
    width: var(--aside-width);
    height: 100%;
    background-color: var(--c-background-secondary);
  }
}
</style>
