<script>
import useLoading from '../store/loading';
import useMedia from '../store/media';

export default {
  name: 'AppStripes',
  setup() {
    const { loading, media } = {
      loading: useLoading(),
      media: useMedia(),
    };

    return {
      loading,
      media,
    };
  },
};
</script>

<template lang="html">
  <div class="stripes" v-if="loading.complete && media.touch"></div>
</template>

<style lang="scss">
@import "../assets/scss/base/mixins";

.stripes {
  @include component-loaded;

  pointer-events: none;
  position: absolute;
  inset: 0 var(--horizontal-offset);
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 1;
    width: var(--stripes-width);
    min-height: calc(100vh + var(--header-height));
    background: repeating-linear-gradient(90deg, var(--stripes-c-thread), var(--stripes-c-thread) 1px, transparent 1px, transparent 12.5%) 0 0,
                repeating-linear-gradient(90deg, var(--stripes-c-thread), var(--stripes-c-thread) 1px, transparent 1px, transparent 12.5%) calc(var(--glob-gap) * -1) 0,
                repeating-linear-gradient(90deg, transparent 0, transparent var(--glob-gap), var(--stripes-c-wide) 0, var(--stripes-c-wide) 12.5%) calc(var(--glob-gap) * -1) 0;
  }
}
</style>
