<script>
import AppImage from './AppImage.vue';

export default {
  name: 'AsideItem',
  components: {
    AppImage,
  },
  props: {
    link: {
      type: String,
      required: true,
    },
    file: {
      type: String,
      required: true,
    },
    project: {
      type: String,
      required: true,
    },
  },
};
</script>

<template>
  <div class="aside-item">
    <AppLink class="link--aside-item" :throttle="1000" :to="link">
      <AppImage :file="file" />

      <div class="display-small">{{ project }}</div>
    </AppLink>
  </div>
</template>

<style lang="scss">
@import "../assets/scss/base/mixins";

.aside-item {
  pointer-events: auto;
  position: absolute;
  top: 0; right: 20px;
  width: calc(var(--aside-width) - 20px);
  user-select: none;
  min-height: 300px;
  padding: 20px 10px 20px 20px;

  .image-container {
    overflow: hidden;
    border-radius: .5px;
  }

  .display-small {
    margin: 0;
    padding: 20px 0 20px 0.3em;
    color: var(--c-text-tertiary);
    //text-transform: uppercase;
    font-weight: 800;
    font-size: 2.8em;
  }
}
</style>
