const routes = [
  {
    path: '/',
    component: () => import('../views/ViewHome.vue'),
    meta: {
      title: 'Максим Ефремов • Веб-разработчик',
      description: 'Помогаю брендам, финансовым и культурным институтам создавать цифровую среду',
    },
  },
  {
    path: '/projects',
    component: () => import('../views/ViewProjects.vue'),
    meta: {
      title: 'Проекты • Максим Ефремов',
      description: 'Помогаю брендам, финансовым и культурным институтам создавать цифровую среду',
    },
  },
  {
    path: '/project',
    redirect: '/projects',
    component: () => import('../views/ViewProject.vue'),
    children: [
      {
        path: 'proxima',
        component: () => import('../views/ViewProjectProxima.vue'),
        meta: {
          title: 'Proxima • Максим Ефремов',
          description: 'Proxima',
        },
      },
      {
        path: 'btn-kit',
        component: () => import('../views/ViewProjectBtnKit.vue'),
        meta: {
          title: 'btn-kit • Максим Ефремов',
          description: 'btn-kit',
        },
      },
      {
        path: 'robotech',
        component: () => import('../views/ViewProjectRobotech.vue'),
        meta: {
          title: 'Robotech • Максим Ефремов',
          description: 'Robotech',
        },
      },
      {
        path: 'photoe',
        component: () => import('../views/ViewProjectPhotoe.vue'),
        meta: {
          title: 'Photoe • Максим Ефремов',
          description: 'Photoe',
        },
      },
      {
        path: 'flick',
        component: () => import('../views/ViewProjectFlick.vue'),
        meta: {
          title: 'Flick • Максим Ефремов',
          description: 'Flick',
        },
      },
      {
        path: 'mariza',
        component: () => import('../views/ViewProjectMariza.vue'),
        meta: {
          title: 'MARIZA • Максим Ефремов',
          description: 'MARIZA',
        },
      },
      {
        path: 'creditor',
        component: () => import('../views/ViewProjectCreditor.vue'),
        meta: {
          title: 'Creditor • Максим Ефремов',
          description: 'Creditor',
        },
      },
    ],
  },
  {
    path: '/contact',
    component: () => import('../views/ViewContact.vue'),
    meta: {
      title: 'Контакты • Максим Ефремов',
      description: 'Помогаю брендам, финансовым и культурным институтам создавать цифровую среду',
    },
  },
  {
    path: '/error',
    component: () => import('../views/ViewError.vue'),
    meta: {
      title: 'Error • Максим Ефремов',
      description: '',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/error',
  },
];

export default routes;
