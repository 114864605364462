<script>
import {
  onBeforeUnmount, onMounted, ref, watch,
} from 'vue';
import { useRoute } from 'vue-router';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import gsapKill from '../utils/gsapKill';
import Timeout from '../utils/Timeout';

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'LayoutApp',
  setup() {
    const { route } = {
      route: useRoute(),
    };

    const indicatorRef = ref();

    const tlIndicator = gsap.timeline();

    const animation = async () => {
      await Timeout.animation(300);

      tlIndicator
        .to(indicatorRef.value, {
          value: 100,
          ease: 'none',
          scrollTrigger: {
            id: 'timeline',
            scrub: true,
          },
        });
    };

    const handleResize = async () => {
      await Timeout.animation(300);

      ScrollTrigger.getById('timeline').refresh();
      tlIndicator.restart();
    };

    const resizeObserver = new ResizeObserver(handleResize);

    onMounted(() => {
      resizeObserver.observe(document.body);

      animation();
    });

    onBeforeUnmount(() => {
      resizeObserver.disconnect();

      ScrollTrigger.getById('timeline').kill();
      gsapKill(tlIndicator);
    });

    watch(route, () => {
      handleResize();
    });

    return {
      indicatorRef,
    };
  },
};
</script>

<template>
  <progress ref="indicatorRef" max="100" value="0"></progress>
</template>

<style lang="scss">
@import "../assets/scss/base/mixins";

progress {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-index-indicator);
  -webkit-appearance: none;
  appearance: none;
  width: 100vw;
  overflow-x: clip;
  height: 5px;
  border: none;
  background: transparent;

  @include min-width(touch) {
    left: 5px;
  }
}

progress::-webkit-progress-bar {
  background: transparent;
}

progress::-webkit-progress-value {
  background-color: var(--c-indian-red);

  @include min-width(touch) {
    background-color: var(--c-cinnabar);
  }
}
</style>
