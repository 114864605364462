import { watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import preloader from '../utils/preloader';
import useLoading from '../store/loading';
import useTransition from '../store/transition';
import useDrawer from '../store/drawer';
import Timeout from '../utils/Timeout';

const transitions = () => {
  const router = useRouter();

  const { drawer, loading, transition } = {
    drawer: useDrawer(),
    loading: useLoading(),
    transition: useTransition(),
  };

  const beforeRoute = async () => { // До навигации по маршруту
    if (!loading.first && !drawer.visible) { // Не первая загрузка и не переход из дравера
      await transition.playExpand(); // Отыгрываем начало анимации перехода
      await Timeout.animation(1200); // Ждем пока закончится начало анимации перехода
    }

    loading.setComplete(false); // Сбрасываем готовность к хиро анимациям
  };

  const afterRoute = async () => { // После навигации по маршруту
    if (loading.first) { // Выполняем один раз при загрузке приложения
      await loading.setFirst(false); // Сообщаем в стор, что первая загрузка была выполнена
      await preloader(); // Запускаем анимацию исчезновения прелоадера
    } else if (!loading.first && drawer.visible) { // Не первая загрузка и переход начат из дравера
      await drawer.setVisible(false); // Закрываем дравер
      await Timeout.animation(600); // Ждем пока отыграет анимация закрытия дравера
    } else if (!loading.first && !drawer.visible) { // Если это переход по внутренним ссылкам
      await transition.playCollapse(); // Выполняем выход из анимации перехода
      await Timeout.animation(300); // Ждем пока отыграет окончание анимации перехода
    }

    await loading.setComplete(true); // Сообщаем в стор, что страница готова к отыгрыванию хиро анимации
  };

  watchEffect(() => {
    router.beforeEach(async (to, from, next) => {
      await beforeRoute();

      next(); // Продолжаем навигацию на новый маршрут
    });

    router.afterEach(async () => {
      await afterRoute();
    });
  });
};

export default transitions;
