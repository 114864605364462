<script>
import { ref, onMounted } from 'vue';

export default {
  name: 'CommonStatus',
  setup() {
    const currentTime = ref('');

    const formatTime = (date) => {
      const options = {
        timeZone: 'Europe/Moscow',
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
      };

      return date.toLocaleTimeString('en-US', options);
    };

    onMounted(() => {
      const getCurrentTime = () => {
        const date = new Date();
        currentTime.value = formatTime(date);
      };

      getCurrentTime();

      setInterval(getCurrentTime, 1000);
    });

    return {
      currentTime,
    };
  },
};
</script>

<template>
  <div class="common common--status">
    <div class="body-medium">Статус</div>

    <div class="common__body">
      <span class="displace">Full-time "IDS LLC"</span>
      <span class="displace">staff member</span>
    </div>
  </div>
</template>
