<script>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { gsap } from 'gsap';
import gsapKill from '../utils/gsapKill';
import useTransition from '../store/transition';

export default {
  name: 'AppTransition',
  setup() {
    const { transition } = {
      transition: useTransition(),
    };

    const transitionRef = ref();
    const transitionShutterRef = ref();

    const expand = gsap.timeline({ paused: true });
    const collapse = gsap.timeline({ paused: true });

    const animation = () => {
      transition.setExpand(expand);
      transition.setCollapse(collapse);

      expand
        .to(transitionRef.value, {
          '--shutter': '0.7',
          duration: 1.2,
          ease: 'power3.inOut',
          onComplete: () => {
            transitionRef.value.style.removeProperty('--shutter');
          },
        })
        .fromTo(transitionShutterRef.value, {
          y: '101%',
        }, {
          y: '-1%',
          duration: 1.2,
          ease: 'expo.inOut',
        }, '<');

      collapse
        .to(transitionShutterRef.value, {
          onComplete: () => {
            gsap.set(transitionShutterRef.value, {
              clearProps: 'all',
            });
          },
          autoAlpha: 0,
          duration: 1,
          ease: 'expo.inOut',
        });
    };

    onMounted(animation);

    onBeforeUnmount(() => {
      gsapKill(expand);
      gsapKill(collapse);
    });

    return {
      transitionRef,
      transitionShutterRef,
    };
  },
};
</script>

<template>
  <div class="app-transition" ref="transitionRef">
    <div class="app-transition-shutter" ref="transitionShutterRef"></div>
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/scss/base/mixins";

.app-transition {
  pointer-events: none;
  position: fixed;
  inset: 0;
  z-index: var(--z-index-app-transition);

  &::before {
    pointer-events: none;
    content: '';
    display: block;
    position: fixed;
    inset: 0;
    background: var(--c-background-secondary);
    opacity: var(--shutter, 0);
    will-change: opacity;
  }

  &-shutter {
    pointer-events: auto;
    width: 100%;
    height: 102%;
    transform: translateY(102%);
    background-color: var(--c-background-primary);
    transform-origin: center bottom;
    will-change: transform;
  }
}
</style>
