class VerticalLoop {
  static gsap;

  static ScrollTrigger;

  #gsap = VerticalLoop.gsap ?? window.gsap;

  #loop = null;

  #items = null;

  #scrollerHeight = 0;

  #itemHeight = 0;

  #wrapHeight = 0;

  #scrollSpeed = 0;

  #oldScrollY = 0;

  #scrollY = 0;

  #y = 0;

  #resizeObserver = null;

  #reachedListener = null;

  #tl = null;

  #handleMouseWheel;

  #rafId = null;

  constructor({ loopEl = '.vertical-loop' } = {}) {
    this.#initLoop(loopEl);
    this.#initEvents();
  }

  static registerGSAP(gsap, ScrollTrigger) {
    VerticalLoop.gsap = gsap;
    VerticalLoop.ScrollTrigger = ScrollTrigger;
  }

  #initLoop(loopEl) {
    this.#loop = loopEl instanceof HTMLElement ? loopEl : document.querySelector(loopEl);

    if (!this.#loop || !this.#loop.children.length) {
      return;
    }

    this.#items = this.#loop.children;
    this.sizes();
  }

  #initEvents() {
    this.#resizeObserver = new ResizeObserver((entries) => {
      entries.forEach(({ target }) => {
        if (target === this.#loop) {
          this.sizes();
        }
      });
    });

    this.#resizeObserver.observe(this.#loop);

    this.#reachedListener = (event) => {
      this.#handleReachingEdges(event);
    };

    document.addEventListener('wheel', this.#reachedListener, { passive: false });
  }

  #handleReachingEdges(event) {
    const { deltaY } = event;
    const docEl = document.documentElement;
    const isScrolledToTop = () => docEl.scrollTop === 0;
    const isScrolledToBottom = () => docEl.scrollTop + window.innerHeight === docEl.scrollHeight;

    if ((isScrolledToTop() && deltaY < 0) || (isScrolledToBottom() && deltaY > 0)) {
      event.preventDefault();

      return;
    }

    if (!this.#loop.contains(event.target)) {
      this.#scrollY += deltaY > 0 ? -6 : 6;
    }
  }

  init() {
    if (!this.#loop) return;

    this.#loop.addEventListener('wheel', ({ deltaY }) => {
      this.#scrollY -= deltaY;
    }, { passive: true });

    this.#updateLoop();
  }

  sizes() {
    if (!this.#items || !this.#items.length) return;

    this.#scrollerHeight = this.#loop.clientHeight;
    this.#itemHeight = this.#items[0]?.clientHeight ?? 0;
    this.#wrapHeight = this.#items.length * this.#itemHeight;
  }

  #updateLoop = () => {
    if (!this.#loop) return;

    this.#y = this.#gsap.utils.interpolate(this.#y, this.#scrollY, 0.025);
    this.#tl = this.#gsap.set(this.#items, {
      y: (i) => `${this.#gsap.utils.wrap(-this.#itemHeight, this.#wrapHeight - this.#itemHeight, i * this.#itemHeight + this.#y)}px`,
    });

    this.#scrollSpeed = this.#y - this.#oldScrollY;
    this.#oldScrollY = this.#y;

    this.#rafId = requestAnimationFrame(this.#updateLoop);
  };

  destroy() {
    if (this.#tl) {
      this.#tl.kill();
    }

    if (this.#loop) {
      this.#loop.removeEventListener('wheel', this.#handleMouseWheel);
    }

    document.removeEventListener('wheel', this.#reachedListener);

    this.#resizeObserver?.unobserve(this.#loop);
    this.#resizeObserver?.disconnect();

    if (this.#rafId) {
      cancelAnimationFrame(this.#rafId);
    }
  }
}

export default VerticalLoop;
