<script>
import { defineAsyncComponent } from 'vue';
import useMedia from '../store/media';
import useLoading from '../store/loading';
import LayoutHeader from './LayoutHeader.vue';
import AppTransition from '../components/AppTransition.vue';
import CursorBlob from '../components/CursorBlob.vue';
import LayoutAside from './LayoutAside.vue';
import AppStripes from '../components/AppStripes.vue';
import ScrollIndicator from '../components/ScrollIndicator.vue';
import LayoutFooter from './LayoutFooter.vue';

const AppHamburger = defineAsyncComponent(() => import('../components/AppHamburger.vue'));
const AppDrawer = defineAsyncComponent(() => import('../components/AppDrawer.vue'));

export default {
  name: 'LayoutApp',
  components: {
    AppTransition,
    LayoutHeader,
    LayoutAside,
    LayoutFooter,
    CursorBlob,
    AppDrawer,
    AppHamburger,
    AppStripes,
    ScrollIndicator,
  },
  setup() {
    const { loading, media } = {
      loading: useLoading(),
      media: useMedia(),
    };

    return {
      loading,
      media,
    };
  },
};
</script>

<template>
  <div class="page">
    <LayoutHeader />
    <main class="main">
      <slot />
    </main>
    <LayoutAside v-if="$route.path !== '/projects' && !($route.path.startsWith('/bin')) && media.touch" />
    <LayoutFooter v-if="loading.complete" />
    <AppStripes />
  </div>
  <AppDrawer />
  <CursorBlob />
  <AppHamburger />
  <AppTransition />
  <ScrollIndicator />
</template>
