import { watch } from 'vue';

const updateMetadata = (router) => {
  watch(
    () => router.currentRoute.value,
    ({ meta: { title, description } }) => {
      document.title = title;
      document.querySelector('meta[name="description"]').content = description;
    },
  );
};

export default updateMetadata;
