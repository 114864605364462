import { createApp } from 'vue';
import { createPinia } from 'pinia';

import AppButton from 'btn-kit';
import AppLink from 'vue-app-link';
import SvgIcon from './components/SvgIcon.vue';
import App from './App.vue';
import router from './router';

const app = createApp(App);

app.use(createPinia());
app.use(router());
app.component('SvgIcon', SvgIcon);
app.component('AppButton', AppButton);
app.component('AppLink', AppLink);

app.mount('#app');
