<script>
export default {
  name: 'WidgetHeading',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<template lang="html">
  <div class="widget-heading">
    <h3 class="display-medium">{{ title }}</h3>

    <div class="widget-heading__body">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/scss/base/mixins";

.widget-heading {
  position: relative;
  margin: 0 var(--horizontal-offset) calc(var(--vertical-offset) * 1.5);
  @include component-loaded;

  &__body {box-shadow: 0 0 0 2px inset red;
    margin-top: calc(var(--vertical-offset) / 3);
    padding-top: var(--glob-gap);
    @include border-side(top);
  }

  h3.display-medium {
    margin-top: 0;
    margin-bottom: 0;
  }
}
</style>
