const imagesData = {
  my_photo_01: {
    img: {
      alt: 'test test',
      width: 440,
      height: 560,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/my_photo_01.png?as=jpeg&width=440',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        110: new URL(
          '../assets/images/my_photo_01.png?as=avif&width=110',
          import.meta.url,
        ),
        220: new URL(
          '../assets/images/my_photo_01.png?as=avif&width=220',
          import.meta.url,
        ),
        330: new URL(
          '../assets/images/my_photo_01.png?as=avif&width=330',
          import.meta.url,
        ),
        440: new URL(
          '../assets/images/my_photo_01.png?as=avif&width=440',
          import.meta.url,
        ),
      },
      webp: {
        110: new URL(
          '../assets/images/my_photo_01.png?as=webp&width=110',
          import.meta.url,
        ),
        220: new URL(
          '../assets/images/my_photo_01.png?as=webp&width=220',
          import.meta.url,
        ),
        330: new URL(
          '../assets/images/my_photo_01.png?as=webp&width=330',
          import.meta.url,
        ),
        440: new URL(
          '../assets/images/my_photo_01.png?as=webp&width=440',
          import.meta.url,
        ),
      },
      jpeg: {
        110: new URL(
          '../assets/images/my_photo_01.png?as=jpeg&width=110',
          import.meta.url,
        ),
        220: new URL(
          '../assets/images/my_photo_01.png?as=jpeg&width=220',
          import.meta.url,
        ),
        330: new URL(
          '../assets/images/my_photo_01.png?as=jpeg&width=330',
          import.meta.url,
        ),
        440: new URL(
          '../assets/images/my_photo_01.png?as=jpeg&width=440',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 110px) 100vw, 110px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  my_photo_02: {
    img: {
      alt: 'test test',
      width: 440,
      height: 560,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/my_photo_02.png?as=jpeg&width=440',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        110: new URL(
          '../assets/images/my_photo_02.png?as=avif&width=110',
          import.meta.url,
        ),
        220: new URL(
          '../assets/images/my_photo_02.png?as=avif&width=220',
          import.meta.url,
        ),
        330: new URL(
          '../assets/images/my_photo_02.png?as=avif&width=330',
          import.meta.url,
        ),
        440: new URL(
          '../assets/images/my_photo_02.png?as=avif&width=440',
          import.meta.url,
        ),
      },
      webp: {
        110: new URL(
          '../assets/images/my_photo_02.png?as=webp&width=110',
          import.meta.url,
        ),
        220: new URL(
          '../assets/images/my_photo_02.png?as=webp&width=220',
          import.meta.url,
        ),
        330: new URL(
          '../assets/images/my_photo_02.png?as=webp&width=330',
          import.meta.url,
        ),
        440: new URL(
          '../assets/images/my_photo_02.png?as=webp&width=440',
          import.meta.url,
        ),
      },
      jpeg: {
        110: new URL(
          '../assets/images/my_photo_02.png?as=jpeg&width=110',
          import.meta.url,
        ),
        220: new URL(
          '../assets/images/my_photo_02.png?as=jpeg&width=220',
          import.meta.url,
        ),
        330: new URL(
          '../assets/images/my_photo_02.png?as=jpeg&width=330',
          import.meta.url,
        ),
        440: new URL(
          '../assets/images/my_photo_02.png?as=jpeg&width=440',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 110px) 100vw, 110px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  my_photo_03: {
    img: {
      alt: 'test test',
      width: 440,
      height: 560,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/my_photo_03.png?as=jpeg&width=440',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        110: new URL(
          '../assets/images/my_photo_03.png?as=avif&width=110',
          import.meta.url,
        ),
        220: new URL(
          '../assets/images/my_photo_03.png?as=avif&width=220',
          import.meta.url,
        ),
        330: new URL(
          '../assets/images/my_photo_03.png?as=avif&width=330',
          import.meta.url,
        ),
        440: new URL(
          '../assets/images/my_photo_03.png?as=avif&width=440',
          import.meta.url,
        ),
      },
      webp: {
        110: new URL(
          '../assets/images/my_photo_03.png?as=webp&width=110',
          import.meta.url,
        ),
        220: new URL(
          '../assets/images/my_photo_03.png?as=webp&width=220',
          import.meta.url,
        ),
        330: new URL(
          '../assets/images/my_photo_03.png?as=webp&width=330',
          import.meta.url,
        ),
        440: new URL(
          '../assets/images/my_photo_03.png?as=webp&width=440',
          import.meta.url,
        ),
      },
      jpeg: {
        110: new URL(
          '../assets/images/my_photo_03.png?as=jpeg&width=110',
          import.meta.url,
        ),
        220: new URL(
          '../assets/images/my_photo_03.png?as=jpeg&width=220',
          import.meta.url,
        ),
        330: new URL(
          '../assets/images/my_photo_03.png?as=jpeg&width=330',
          import.meta.url,
        ),
        440: new URL(
          '../assets/images/my_photo_03.png?as=jpeg&width=440',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 110px) 100vw, 110px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },

  workflow_01: {
    img: {
      alt: 'test test',
      width: 860,
      height: 1558,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/workflow_01.png?as=jpeg&width=1500',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        320: new URL(
          '../assets/images/workflow_01.png?as=avif&width=320',
          import.meta.url,
        ),
        430: new URL(
          '../assets/images/workflow_01.png?as=avif&width=430',
          import.meta.url,
        ),
        646: new URL(
          '../assets/images/workflow_01.png?as=avif&width=646',
          import.meta.url,
        ),
        860: new URL(
          '../assets/images/workflow_01.png?as=avif&width=860',
          import.meta.url,
        ),
      },
      webp: {
        320: new URL(
          '../assets/images/workflow_01.png?as=webp&width=320',
          import.meta.url,
        ),
        430: new URL(
          '../assets/images/workflow_01.png?as=webp&width=430',
          import.meta.url,
        ),
        646: new URL(
          '../assets/images/workflow_01.png?as=webp&width=646',
          import.meta.url,
        ),
        860: new URL(
          '../assets/images/workflow_01.png?as=webp&width=860',
          import.meta.url,
        ),
      },
      jpeg: {
        320: new URL(
          '../assets/images/workflow_01.png?as=jpeg&width=320',
          import.meta.url,
        ),
        430: new URL(
          '../assets/images/workflow_01.png?as=jpeg&width=430',
          import.meta.url,
        ),
        646: new URL(
          '../assets/images/workflow_01.png?as=jpeg&width=646',
          import.meta.url,
        ),
        860: new URL(
          '../assets/images/workflow_01.png?as=jpeg&width=860',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 646px) 100vw, 646px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  workflow_02: {
    img: {
      alt: 'test test',
      width: 860,
      height: 1558,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/workflow_02.png?as=jpeg&width=1500',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        320: new URL(
          '../assets/images/workflow_02.png?as=avif&width=320',
          import.meta.url,
        ),
        430: new URL(
          '../assets/images/workflow_02.png?as=avif&width=430',
          import.meta.url,
        ),
        646: new URL(
          '../assets/images/workflow_02.png?as=avif&width=646',
          import.meta.url,
        ),
        860: new URL(
          '../assets/images/workflow_02.png?as=avif&width=860',
          import.meta.url,
        ),
      },
      webp: {
        320: new URL(
          '../assets/images/workflow_02.png?as=webp&width=320',
          import.meta.url,
        ),
        430: new URL(
          '../assets/images/workflow_02.png?as=webp&width=430',
          import.meta.url,
        ),
        646: new URL(
          '../assets/images/workflow_02.png?as=webp&width=646',
          import.meta.url,
        ),
        860: new URL(
          '../assets/images/workflow_02.png?as=webp&width=860',
          import.meta.url,
        ),
      },
      jpeg: {
        320: new URL(
          '../assets/images/workflow_02.png?as=jpeg&width=320',
          import.meta.url,
        ),
        430: new URL(
          '../assets/images/workflow_02.png?as=jpeg&width=430',
          import.meta.url,
        ),
        646: new URL(
          '../assets/images/workflow_02.png?as=jpeg&width=646',
          import.meta.url,
        ),
        860: new URL(
          '../assets/images/workflow_02.png?as=jpeg&width=860',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 646px) 100vw, 646px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  workflow_03: {
    img: {
      alt: 'test test',
      width: 860,
      height: 1558,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/workflow_03.png?as=jpeg&width=1500',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        320: new URL(
          '../assets/images/workflow_03.png?as=avif&width=320',
          import.meta.url,
        ),
        430: new URL(
          '../assets/images/workflow_03.png?as=avif&width=430',
          import.meta.url,
        ),
        646: new URL(
          '../assets/images/workflow_03.png?as=avif&width=646',
          import.meta.url,
        ),
        860: new URL(
          '../assets/images/workflow_03.png?as=avif&width=860',
          import.meta.url,
        ),
      },
      webp: {
        320: new URL(
          '../assets/images/workflow_03.png?as=webp&width=320',
          import.meta.url,
        ),
        430: new URL(
          '../assets/images/workflow_03.png?as=webp&width=430',
          import.meta.url,
        ),
        646: new URL(
          '../assets/images/workflow_03.png?as=webp&width=646',
          import.meta.url,
        ),
        860: new URL(
          '../assets/images/workflow_03.png?as=webp&width=860',
          import.meta.url,
        ),
      },
      jpeg: {
        320: new URL(
          '../assets/images/workflow_03.png?as=jpeg&width=320',
          import.meta.url,
        ),
        430: new URL(
          '../assets/images/workflow_03.png?as=jpeg&width=430',
          import.meta.url,
        ),
        646: new URL(
          '../assets/images/workflow_03.png?as=jpeg&width=646',
          import.meta.url,
        ),
        860: new URL(
          '../assets/images/workflow_03.png?as=jpeg&width=860',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 646px) 100vw, 646px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },

  aside_btnkit_01: {
    img: {
      alt: 'test test',
      width: 1200,
      height: 1580,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/aside/aside_btnkit_01.png?as=jpeg&width=1200',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        260: new URL(
          '../assets/images/aside/aside_btnkit_01.png?as=avif&width=260',
          import.meta.url,
        ),
        400: new URL(
          '../assets/images/aside/aside_btnkit_01.png?as=avif&width=400',
          import.meta.url,
        ),
        800: new URL(
          '../assets/images/aside/aside_btnkit_01.png?as=avif&width=800',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/aside/aside_btnkit_01.png?as=avif&width=1200',
          import.meta.url,
        ),
      },
      webp: {
        260: new URL(
          '../assets/images/aside/aside_btnkit_01.png?as=webp&width=260',
          import.meta.url,
        ),
        400: new URL(
          '../assets/images/aside/aside_btnkit_01.png?as=webp&width=400',
          import.meta.url,
        ),
        800: new URL(
          '../assets/images/aside/aside_btnkit_01.png?as=webp&width=800',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/aside/aside_btnkit_01.png?as=webp&width=1200',
          import.meta.url,
        ),
      },
      jpeg: {
        260: new URL(
          '../assets/images/aside/aside_btnkit_01.png?as=jpeg&width=260',
          import.meta.url,
        ),
        400: new URL(
          '../assets/images/aside/aside_btnkit_01.png?as=jpeg&width=400',
          import.meta.url,
        ),
        800: new URL(
          '../assets/images/aside/aside_btnkit_01.png?as=jpeg&width=800',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/aside/aside_btnkit_01.png?as=jpeg&width=1200',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 400px) 100vw, 400px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  aside_proxima_01: {
    img: {
      alt: 'test test',
      width: 1200,
      height: 1580,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/aside/aside_proxima_01.png?as=jpeg&width=1200',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        260: new URL(
          '../assets/images/aside/aside_proxima_01.png?as=avif&width=260',
          import.meta.url,
        ),
        400: new URL(
          '../assets/images/aside/aside_proxima_01.png?as=avif&width=400',
          import.meta.url,
        ),
        800: new URL(
          '../assets/images/aside/aside_proxima_01.png?as=avif&width=800',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/aside/aside_proxima_01.png?as=avif&width=1200',
          import.meta.url,
        ),
      },
      webp: {
        260: new URL(
          '../assets/images/aside/aside_proxima_01.png?as=webp&width=260',
          import.meta.url,
        ),
        400: new URL(
          '../assets/images/aside/aside_proxima_01.png?as=webp&width=400',
          import.meta.url,
        ),
        800: new URL(
          '../assets/images/aside/aside_proxima_01.png?as=webp&width=800',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/aside/aside_proxima_01.png?as=webp&width=1200',
          import.meta.url,
        ),
      },
      jpeg: {
        260: new URL(
          '../assets/images/aside/aside_proxima_01.png?as=jpeg&width=260',
          import.meta.url,
        ),
        400: new URL(
          '../assets/images/aside/aside_proxima_01.png?as=jpeg&width=400',
          import.meta.url,
        ),
        800: new URL(
          '../assets/images/aside/aside_proxima_01.png?as=jpeg&width=800',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/aside/aside_proxima_01.png?as=jpeg&width=1200',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 400px) 100vw, 400px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  aside_flick_01: {
    img: {
      alt: 'test test',
      width: 1200,
      height: 1580,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/aside/aside_flick_01.png?as=jpeg&width=1200',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        260: new URL(
          '../assets/images/aside/aside_flick_01.png?as=avif&width=260',
          import.meta.url,
        ),
        400: new URL(
          '../assets/images/aside/aside_flick_01.png?as=avif&width=400',
          import.meta.url,
        ),
        800: new URL(
          '../assets/images/aside/aside_flick_01.png?as=avif&width=800',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/aside/aside_flick_01.png?as=avif&width=1200',
          import.meta.url,
        ),
      },
      webp: {
        260: new URL(
          '../assets/images/aside/aside_flick_01.png?as=webp&width=260',
          import.meta.url,
        ),
        400: new URL(
          '../assets/images/aside/aside_flick_01.png?as=webp&width=400',
          import.meta.url,
        ),
        800: new URL(
          '../assets/images/aside/aside_flick_01.png?as=webp&width=800',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/aside/aside_flick_01.png?as=webp&width=1200',
          import.meta.url,
        ),
      },
      jpeg: {
        260: new URL(
          '../assets/images/aside/aside_flick_01.png?as=jpeg&width=260',
          import.meta.url,
        ),
        400: new URL(
          '../assets/images/aside/aside_flick_01.png?as=jpeg&width=400',
          import.meta.url,
        ),
        800: new URL(
          '../assets/images/aside/aside_flick_01.png?as=jpeg&width=800',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/aside/aside_flick_01.png?as=jpeg&width=1200',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 400px) 100vw, 400px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  aside_mariza_01: {
    img: {
      alt: 'test test',
      width: 1200,
      height: 1580,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/aside/aside_mariza_01.png?as=jpeg&width=1200',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        260: new URL(
          '../assets/images/aside/aside_mariza_01.png?as=avif&width=260',
          import.meta.url,
        ),
        400: new URL(
          '../assets/images/aside/aside_mariza_01.png?as=avif&width=400',
          import.meta.url,
        ),
        800: new URL(
          '../assets/images/aside/aside_mariza_01.png?as=avif&width=800',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/aside/aside_mariza_01.png?as=avif&width=1200',
          import.meta.url,
        ),
      },
      webp: {
        260: new URL(
          '../assets/images/aside/aside_mariza_01.png?as=webp&width=260',
          import.meta.url,
        ),
        400: new URL(
          '../assets/images/aside/aside_mariza_01.png?as=webp&width=400',
          import.meta.url,
        ),
        800: new URL(
          '../assets/images/aside/aside_mariza_01.png?as=webp&width=800',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/aside/aside_mariza_01.png?as=webp&width=1200',
          import.meta.url,
        ),
      },
      jpeg: {
        260: new URL(
          '../assets/images/aside/aside_mariza_01.png?as=jpeg&width=260',
          import.meta.url,
        ),
        400: new URL(
          '../assets/images/aside/aside_mariza_01.png?as=jpeg&width=400',
          import.meta.url,
        ),
        800: new URL(
          '../assets/images/aside/aside_mariza_01.png?as=jpeg&width=800',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/aside/aside_mariza_01.png?as=jpeg&width=1200',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 400px) 100vw, 400px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  aside_photoe_01: {
    img: {
      alt: 'test test',
      width: 1200,
      height: 1580,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/aside/aside_photoe_01.png?as=jpeg&width=1200',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        260: new URL(
          '../assets/images/aside/aside_photoe_01.png?as=avif&width=260',
          import.meta.url,
        ),
        400: new URL(
          '../assets/images/aside/aside_photoe_01.png?as=avif&width=400',
          import.meta.url,
        ),
        800: new URL(
          '../assets/images/aside/aside_photoe_01.png?as=avif&width=800',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/aside/aside_photoe_01.png?as=avif&width=1200',
          import.meta.url,
        ),
      },
      webp: {
        260: new URL(
          '../assets/images/aside/aside_photoe_01.png?as=webp&width=260',
          import.meta.url,
        ),
        400: new URL(
          '../assets/images/aside/aside_photoe_01.png?as=webp&width=400',
          import.meta.url,
        ),
        800: new URL(
          '../assets/images/aside/aside_photoe_01.png?as=webp&width=800',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/aside/aside_photoe_01.png?as=webp&width=1200',
          import.meta.url,
        ),
      },
      jpeg: {
        260: new URL(
          '../assets/images/aside/aside_photoe_01.png?as=jpeg&width=260',
          import.meta.url,
        ),
        400: new URL(
          '../assets/images/aside/aside_photoe_01.png?as=jpeg&width=400',
          import.meta.url,
        ),
        800: new URL(
          '../assets/images/aside/aside_photoe_01.png?as=jpeg&width=800',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/aside/aside_photoe_01.png?as=jpeg&width=1200',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 400px) 100vw, 400px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  aside_robotech_01: {
    img: {
      alt: 'test test',
      width: 1200,
      height: 1580,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/aside/aside_robotech_01.png?as=jpeg&width=1200',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        260: new URL(
          '../assets/images/aside/aside_robotech_01.png?as=avif&width=260',
          import.meta.url,
        ),
        400: new URL(
          '../assets/images/aside/aside_robotech_01.png?as=avif&width=400',
          import.meta.url,
        ),
        800: new URL(
          '../assets/images/aside/aside_robotech_01.png?as=avif&width=800',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/aside/aside_robotech_01.png?as=avif&width=1200',
          import.meta.url,
        ),
      },
      webp: {
        260: new URL(
          '../assets/images/aside/aside_robotech_01.png?as=webp&width=260',
          import.meta.url,
        ),
        400: new URL(
          '../assets/images/aside/aside_robotech_01.png?as=webp&width=400',
          import.meta.url,
        ),
        800: new URL(
          '../assets/images/aside/aside_robotech_01.png?as=webp&width=800',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/aside/aside_robotech_01.png?as=webp&width=1200',
          import.meta.url,
        ),
      },
      jpeg: {
        260: new URL(
          '../assets/images/aside/aside_robotech_01.png?as=jpeg&width=260',
          import.meta.url,
        ),
        400: new URL(
          '../assets/images/aside/aside_robotech_01.png?as=jpeg&width=400',
          import.meta.url,
        ),
        800: new URL(
          '../assets/images/aside/aside_robotech_01.png?as=jpeg&width=800',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/aside/aside_robotech_01.png?as=jpeg&width=1200',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 400px) 100vw, 400px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },

  proxima_full_01: {
    img: {
      alt: 'test test',
      width: 3000,
      height: 2250,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/project/proxima/proxima_full_01.png?as=jpeg&width=3000',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        480: new URL(
          '../assets/images/project/proxima/proxima_full_01.png?as=avif&width=480',
          import.meta.url,
        ),
        800: new URL(
          '../assets/images/project/proxima/proxima_full_01.png?as=avif&width=800',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/project/proxima/proxima_full_01.png?as=avif&width=1200',
          import.meta.url,
        ),
        1600: new URL(
          '../assets/images/project/proxima/proxima_full_01.png?as=avif&width=1600',
          import.meta.url,
        ),
        2200: new URL(
          '../assets/images/project/proxima/proxima_full_01.png?as=avif&width=2200',
          import.meta.url,
        ),
        3000: new URL(
          '../assets/images/project/proxima/proxima_full_01.png?as=avif&width=3000',
          import.meta.url,
        ),
      },
      webp: {
        480: new URL(
          '../assets/images/project/proxima/proxima_full_01.png?as=webp&width=480',
          import.meta.url,
        ),
        800: new URL(
          '../assets/images/project/proxima/proxima_full_01.png?as=webp&width=800',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/project/proxima/proxima_full_01.png?as=webp&width=1200',
          import.meta.url,
        ),
        1600: new URL(
          '../assets/images/project/proxima/proxima_full_01.png?as=webp&width=1600',
          import.meta.url,
        ),
        2200: new URL(
          '../assets/images/project/proxima/proxima_full_01.png?as=webp&width=2200',
          import.meta.url,
        ),
        3000: new URL(
          '../assets/images/project/proxima/proxima_full_01.png?as=webp&width=3000',
          import.meta.url,
        ),
      },
      jpeg: {
        480: new URL(
          '../assets/images/project/proxima/proxima_full_01.png?as=jpeg&width=480',
          import.meta.url,
        ),
        800: new URL(
          '../assets/images/project/proxima/proxima_full_01.png?as=jpeg&width=800',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/project/proxima/proxima_full_01.png?as=jpeg&width=1200',
          import.meta.url,
        ),
        1600: new URL(
          '../assets/images/project/proxima/proxima_full_01.png?as=jpeg&width=1600',
          import.meta.url,
        ),
        2200: new URL(
          '../assets/images/project/proxima/proxima_full_01.png?as=jpeg&width=2200',
          import.meta.url,
        ),
        3000: new URL(
          '../assets/images/project/proxima/proxima_full_01.png?as=jpeg&width=3000',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 1600px) 100vw, 1600px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  proxima_mobile_01: {
    img: {
      alt: 'test test',
      width: 462,
      height: 932,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/project/proxima/proxima_mobile_01.png?as=jpeg&width=462',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        138: new URL(
          '../assets/images/project/proxima/proxima_mobile_01.png?as=avif&width=138',
          import.meta.url,
        ),
        178: new URL(
          '../assets/images/project/proxima/proxima_mobile_01.png?as=avif&width=178',
          import.meta.url,
        ),
        230: new URL(
          '../assets/images/project/proxima/proxima_mobile_01.png?as=avif&width=230',
          import.meta.url,
        ),
        320: new URL(
          '../assets/images/project/proxima/proxima_mobile_01.png?as=avif&width=320',
          import.meta.url,
        ),
        462: new URL(
          '../assets/images/project/proxima/proxima_mobile_01.png?as=avif&width=462',
          import.meta.url,
        ),
      },
      webp: {
        138: new URL(
          '../assets/images/project/proxima/proxima_mobile_01.png?as=webp&width=138',
          import.meta.url,
        ),
        178: new URL(
          '../assets/images/project/proxima/proxima_mobile_01.png?as=webp&width=178',
          import.meta.url,
        ),
        230: new URL(
          '../assets/images/project/proxima/proxima_mobile_01.png?as=webp&width=230',
          import.meta.url,
        ),
        320: new URL(
          '../assets/images/project/proxima/proxima_mobile_01.png?as=webp&width=320',
          import.meta.url,
        ),
        462: new URL(
          '../assets/images/project/proxima/proxima_mobile_01.png?as=webp&width=462',
          import.meta.url,
        ),
      },
      jpeg: {
        138: new URL(
          '../assets/images/project/proxima/proxima_mobile_01.png?as=jpeg&width=138',
          import.meta.url,
        ),
        178: new URL(
          '../assets/images/project/proxima/proxima_mobile_01.png?as=jpeg&width=178',
          import.meta.url,
        ),
        230: new URL(
          '../assets/images/project/proxima/proxima_mobile_01.png?as=jpeg&width=230',
          import.meta.url,
        ),
        320: new URL(
          '../assets/images/project/proxima/proxima_mobile_01.png?as=jpeg&width=320',
          import.meta.url,
        ),
        462: new URL(
          '../assets/images/project/proxima/proxima_mobile_01.png?as=jpeg&width=462',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 462px) 100vw, 462px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  proxima_mobile_02: {
    img: {
      alt: 'test test',
      width: 462,
      height: 932,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/project/proxima/proxima_mobile_02.png?as=jpeg&width=462',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        138: new URL(
          '../assets/images/project/proxima/proxima_mobile_02.png?as=avif&width=138',
          import.meta.url,
        ),
        178: new URL(
          '../assets/images/project/proxima/proxima_mobile_02.png?as=avif&width=178',
          import.meta.url,
        ),
        230: new URL(
          '../assets/images/project/proxima/proxima_mobile_02.png?as=avif&width=230',
          import.meta.url,
        ),
        320: new URL(
          '../assets/images/project/proxima/proxima_mobile_02.png?as=avif&width=320',
          import.meta.url,
        ),
        462: new URL(
          '../assets/images/project/proxima/proxima_mobile_02.png?as=avif&width=462',
          import.meta.url,
        ),
      },
      webp: {
        138: new URL(
          '../assets/images/project/proxima/proxima_mobile_02.png?as=webp&width=138',
          import.meta.url,
        ),
        178: new URL(
          '../assets/images/project/proxima/proxima_mobile_02.png?as=webp&width=178',
          import.meta.url,
        ),
        230: new URL(
          '../assets/images/project/proxima/proxima_mobile_02.png?as=webp&width=230',
          import.meta.url,
        ),
        320: new URL(
          '../assets/images/project/proxima/proxima_mobile_02.png?as=webp&width=320',
          import.meta.url,
        ),
        462: new URL(
          '../assets/images/project/proxima/proxima_mobile_02.png?as=webp&width=462',
          import.meta.url,
        ),
      },
      jpeg: {
        138: new URL(
          '../assets/images/project/proxima/proxima_mobile_02.png?as=jpeg&width=138',
          import.meta.url,
        ),
        178: new URL(
          '../assets/images/project/proxima/proxima_mobile_02.png?as=jpeg&width=178',
          import.meta.url,
        ),
        230: new URL(
          '../assets/images/project/proxima/proxima_mobile_02.png?as=jpeg&width=230',
          import.meta.url,
        ),
        320: new URL(
          '../assets/images/project/proxima/proxima_mobile_02.png?as=jpeg&width=320',
          import.meta.url,
        ),
        462: new URL(
          '../assets/images/project/proxima/proxima_mobile_02.png?as=jpeg&width=462',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 462px) 100vw, 462px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  proxima_mobile_03: {
    img: {
      alt: 'test test',
      width: 462,
      height: 932,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/project/proxima/proxima_mobile_03.png?as=jpeg&width=462',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        138: new URL(
          '../assets/images/project/proxima/proxima_mobile_03.png?as=avif&width=138',
          import.meta.url,
        ),
        178: new URL(
          '../assets/images/project/proxima/proxima_mobile_03.png?as=avif&width=178',
          import.meta.url,
        ),
        230: new URL(
          '../assets/images/project/proxima/proxima_mobile_03.png?as=avif&width=230',
          import.meta.url,
        ),
        320: new URL(
          '../assets/images/project/proxima/proxima_mobile_03.png?as=avif&width=320',
          import.meta.url,
        ),
        462: new URL(
          '../assets/images/project/proxima/proxima_mobile_03.png?as=avif&width=462',
          import.meta.url,
        ),
      },
      webp: {
        138: new URL(
          '../assets/images/project/proxima/proxima_mobile_03.png?as=webp&width=138',
          import.meta.url,
        ),
        178: new URL(
          '../assets/images/project/proxima/proxima_mobile_03.png?as=webp&width=178',
          import.meta.url,
        ),
        230: new URL(
          '../assets/images/project/proxima/proxima_mobile_03.png?as=webp&width=230',
          import.meta.url,
        ),
        320: new URL(
          '../assets/images/project/proxima/proxima_mobile_03.png?as=webp&width=320',
          import.meta.url,
        ),
        462: new URL(
          '../assets/images/project/proxima/proxima_mobile_03.png?as=webp&width=462',
          import.meta.url,
        ),
      },
      jpeg: {
        138: new URL(
          '../assets/images/project/proxima/proxima_mobile_03.png?as=jpeg&width=138',
          import.meta.url,
        ),
        178: new URL(
          '../assets/images/project/proxima/proxima_mobile_03.png?as=jpeg&width=178',
          import.meta.url,
        ),
        230: new URL(
          '../assets/images/project/proxima/proxima_mobile_03.png?as=jpeg&width=230',
          import.meta.url,
        ),
        320: new URL(
          '../assets/images/project/proxima/proxima_mobile_03.png?as=jpeg&width=320',
          import.meta.url,
        ),
        462: new URL(
          '../assets/images/project/proxima/proxima_mobile_03.png?as=jpeg&width=462',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 462px) 100vw, 462px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  proxima_banner_01: {
    img: {
      alt: 'test test',
      width: 2000,
      height: 1600,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/project/proxima/proxima_banner_01.png?as=jpeg&width=420',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        420: new URL(
          '../assets/images/project/proxima/proxima_banner_01.png?as=avif&width=420',
          import.meta.url,
        ),
        680: new URL(
          '../assets/images/project/proxima/proxima_banner_01.png?as=avif&width=680',
          import.meta.url,
        ),
        900: new URL(
          '../assets/images/project/proxima/proxima_banner_01.png?as=avif&width=900',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/project/proxima/proxima_banner_01.png?as=avif&width=1200',
          import.meta.url,
        ),
        1600: new URL(
          '../assets/images/project/proxima/proxima_banner_01.png?as=avif&width=1600',
          import.meta.url,
        ),
        2000: new URL(
          '../assets/images/project/proxima/proxima_banner_01.png?as=avif&width=2000',
          import.meta.url,
        ),
      },
      webp: {
        420: new URL(
          '../assets/images/project/proxima/proxima_banner_01.png?as=webp&width=420',
          import.meta.url,
        ),
        680: new URL(
          '../assets/images/project/proxima/proxima_banner_01.png?as=webp&width=680',
          import.meta.url,
        ),
        900: new URL(
          '../assets/images/project/proxima/proxima_banner_01.png?as=webp&width=900',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/project/proxima/proxima_banner_01.png?as=webp&width=1200',
          import.meta.url,
        ),
        1600: new URL(
          '../assets/images/project/proxima/proxima_banner_01.png?as=webp&width=1600',
          import.meta.url,
        ),
        2000: new URL(
          '../assets/images/project/proxima/proxima_banner_01.png?as=webp&width=2000',
          import.meta.url,
        ),
      },
      jpeg: {
        420: new URL(
          '../assets/images/project/proxima/proxima_banner_01.png?as=jpeg&width=420',
          import.meta.url,
        ),
        680: new URL(
          '../assets/images/project/proxima/proxima_banner_01.png?as=jpeg&width=680',
          import.meta.url,
        ),
        900: new URL(
          '../assets/images/project/proxima/proxima_banner_01.png?as=jpeg&width=900',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/project/proxima/proxima_banner_01.png?as=jpeg&width=1200',
          import.meta.url,
        ),
        1600: new URL(
          '../assets/images/project/proxima/proxima_banner_01.png?as=jpeg&width=1600',
          import.meta.url,
        ),
        2000: new URL(
          '../assets/images/project/proxima/proxima_banner_01.png?as=jpeg&width=2000',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 1400px) 100vw, 1400px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },

  robotech_banner_01: {
    img: {
      alt: 'test test',
      width: 2000,
      height: 1600,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/project/robotech/robotech_banner_01.png?as=jpeg&width=420',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        420: new URL(
          '../assets/images/project/robotech/robotech_banner_01.png?as=avif&width=420',
          import.meta.url,
        ),
        680: new URL(
          '../assets/images/project/robotech/robotech_banner_01.png?as=avif&width=680',
          import.meta.url,
        ),
        900: new URL(
          '../assets/images/project/robotech/robotech_banner_01.png?as=avif&width=900',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/project/robotech/robotech_banner_01.png?as=avif&width=1200',
          import.meta.url,
        ),
        1600: new URL(
          '../assets/images/project/robotech/robotech_banner_01.png?as=avif&width=1600',
          import.meta.url,
        ),
        2000: new URL(
          '../assets/images/project/robotech/robotech_banner_01.png?as=avif&width=2000',
          import.meta.url,
        ),
      },
      webp: {
        420: new URL(
          '../assets/images/project/robotech/robotech_banner_01.png?as=webp&width=420',
          import.meta.url,
        ),
        680: new URL(
          '../assets/images/project/robotech/robotech_banner_01.png?as=webp&width=680',
          import.meta.url,
        ),
        900: new URL(
          '../assets/images/project/robotech/robotech_banner_01.png?as=webp&width=900',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/project/robotech/robotech_banner_01.png?as=webp&width=1200',
          import.meta.url,
        ),
        1600: new URL(
          '../assets/images/project/robotech/robotech_banner_01.png?as=webp&width=1600',
          import.meta.url,
        ),
        2000: new URL(
          '../assets/images/project/robotech/robotech_banner_01.png?as=webp&width=2000',
          import.meta.url,
        ),
      },
      jpeg: {
        420: new URL(
          '../assets/images/project/robotech/robotech_banner_01.png?as=jpeg&width=420',
          import.meta.url,
        ),
        680: new URL(
          '../assets/images/project/robotech/robotech_banner_01.png?as=jpeg&width=680',
          import.meta.url,
        ),
        900: new URL(
          '../assets/images/project/robotech/robotech_banner_01.png?as=jpeg&width=900',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/project/robotech/robotech_banner_01.png?as=jpeg&width=1200',
          import.meta.url,
        ),
        1600: new URL(
          '../assets/images/project/robotech/robotech_banner_01.png?as=jpeg&width=1600',
          import.meta.url,
        ),
        2000: new URL(
          '../assets/images/project/robotech/robotech_banner_01.png?as=jpeg&width=2000',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 1400px) 100vw, 1400px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },

  projects_proxima_01: {
    img: {
      alt: 'test test',
      width: 1200,
      height: 1000,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/projects/projects_proxima_01.png?as=jpeg&width=1200',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        660: new URL(
          '../assets/images/projects/projects_proxima_01.png?as=avif&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_proxima_01.png?as=avif&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_proxima_01.png?as=avif&width=1200',
          import.meta.url,
        ),
      },
      webp: {
        660: new URL(
          '../assets/images/projects/projects_proxima_01.png?as=webp&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_proxima_01.png?as=webp&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_proxima_01.png?as=webp&width=1200',
          import.meta.url,
        ),
      },
      jpeg: {
        660: new URL(
          '../assets/images/projects/projects_proxima_01.png?as=jpeg&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_proxima_01.png?as=jpeg&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_proxima_01.png?as=jpeg&width=1200',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 1200px) 100vw, 1200px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  projects_proxima_02: {
    img: {
      alt: 'test test',
      width: 1200,
      height: 1000,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/projects/projects_proxima_02.png?as=jpeg&width=1200',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        660: new URL(
          '../assets/images/projects/projects_proxima_02.png?as=avif&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_proxima_02.png?as=avif&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_proxima_02.png?as=avif&width=1200',
          import.meta.url,
        ),
      },
      webp: {
        660: new URL(
          '../assets/images/projects/projects_proxima_02.png?as=webp&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_proxima_02.png?as=webp&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_proxima_02.png?as=webp&width=1200',
          import.meta.url,
        ),
      },
      jpeg: {
        660: new URL(
          '../assets/images/projects/projects_proxima_02.png?as=jpeg&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_proxima_02.png?as=jpeg&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_proxima_02.png?as=jpeg&width=1200',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 1200px) 100vw, 1200px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  projects_btnkit_01: {
    img: {
      alt: 'test test',
      width: 1200,
      height: 1000,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/projects/projects_btnkit_01.png?as=jpeg&width=1200',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        660: new URL(
          '../assets/images/projects/projects_btnkit_01.png?as=avif&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_btnkit_01.png?as=avif&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_btnkit_01.png?as=avif&width=1200',
          import.meta.url,
        ),
      },
      webp: {
        660: new URL(
          '../assets/images/projects/projects_btnkit_01.png?as=webp&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_btnkit_01.png?as=webp&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_btnkit_01.png?as=webp&width=1200',
          import.meta.url,
        ),
      },
      jpeg: {
        660: new URL(
          '../assets/images/projects/projects_btnkit_01.png?as=jpeg&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_btnkit_01.png?as=jpeg&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_btnkit_01.png?as=jpeg&width=1200',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 1200px) 100vw, 1200px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  projects_btnkit_02: {
    img: {
      alt: 'test test',
      width: 1200,
      height: 1000,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/projects/projects_btnkit_02.png?as=jpeg&width=1200',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        660: new URL(
          '../assets/images/projects/projects_btnkit_02.png?as=avif&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_btnkit_02.png?as=avif&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_btnkit_02.png?as=avif&width=1200',
          import.meta.url,
        ),
      },
      webp: {
        660: new URL(
          '../assets/images/projects/projects_btnkit_02.png?as=webp&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_btnkit_02.png?as=webp&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_btnkit_02.png?as=webp&width=1200',
          import.meta.url,
        ),
      },
      jpeg: {
        660: new URL(
          '../assets/images/projects/projects_btnkit_02.png?as=jpeg&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_btnkit_02.png?as=jpeg&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_btnkit_02.png?as=jpeg&width=1200',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 1200px) 100vw, 1200px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  projects_robotech_01: {
    img: {
      alt: 'test test',
      width: 1200,
      height: 1000,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/projects/projects_robotech_01.png?as=jpeg&width=1200',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        660: new URL(
          '../assets/images/projects/projects_robotech_01.png?as=avif&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_robotech_01.png?as=avif&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_robotech_01.png?as=avif&width=1200',
          import.meta.url,
        ),
      },
      webp: {
        660: new URL(
          '../assets/images/projects/projects_robotech_01.png?as=webp&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_robotech_01.png?as=webp&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_robotech_01.png?as=webp&width=1200',
          import.meta.url,
        ),
      },
      jpeg: {
        660: new URL(
          '../assets/images/projects/projects_robotech_01.png?as=jpeg&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_robotech_01.png?as=jpeg&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_robotech_01.png?as=jpeg&width=1200',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 1200px) 100vw, 1200px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  projects_robotech_02: {
    img: {
      alt: 'test test',
      width: 1200,
      height: 1000,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/projects/projects_robotech_02.png?as=jpeg&width=1200',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        660: new URL(
          '../assets/images/projects/projects_robotech_02.png?as=avif&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_robotech_02.png?as=avif&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_robotech_02.png?as=avif&width=1200',
          import.meta.url,
        ),
      },
      webp: {
        660: new URL(
          '../assets/images/projects/projects_robotech_02.png?as=webp&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_robotech_02.png?as=webp&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_robotech_02.png?as=webp&width=1200',
          import.meta.url,
        ),
      },
      jpeg: {
        660: new URL(
          '../assets/images/projects/projects_robotech_02.png?as=jpeg&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_robotech_02.png?as=jpeg&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_robotech_02.png?as=jpeg&width=1200',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 1200px) 100vw, 1200px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  projects_photoe_01: {
    img: {
      alt: 'test test',
      width: 1200,
      height: 1000,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/projects/projects_photoe_01.png?as=jpeg&width=1200',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        660: new URL(
          '../assets/images/projects/projects_photoe_01.png?as=avif&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_photoe_01.png?as=avif&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_photoe_01.png?as=avif&width=1200',
          import.meta.url,
        ),
      },
      webp: {
        660: new URL(
          '../assets/images/projects/projects_photoe_01.png?as=webp&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_photoe_01.png?as=webp&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_photoe_01.png?as=webp&width=1200',
          import.meta.url,
        ),
      },
      jpeg: {
        660: new URL(
          '../assets/images/projects/projects_photoe_01.png?as=jpeg&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_photoe_01.png?as=jpeg&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_photoe_01.png?as=jpeg&width=1200',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 1200px) 100vw, 1200px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  projects_photoe_02: {
    img: {
      alt: 'test test',
      width: 1200,
      height: 1000,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/projects/projects_photoe_02.png?as=jpeg&width=1200',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        660: new URL(
          '../assets/images/projects/projects_photoe_02.png?as=avif&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_photoe_02.png?as=avif&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_photoe_02.png?as=avif&width=1200',
          import.meta.url,
        ),
      },
      webp: {
        660: new URL(
          '../assets/images/projects/projects_photoe_02.png?as=webp&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_photoe_02.png?as=webp&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_photoe_02.png?as=webp&width=1200',
          import.meta.url,
        ),
      },
      jpeg: {
        660: new URL(
          '../assets/images/projects/projects_photoe_02.png?as=jpeg&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_photoe_02.png?as=jpeg&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_photoe_02.png?as=jpeg&width=1200',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 1200px) 100vw, 1200px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  projects_flick_01: {
    img: {
      alt: 'test test',
      width: 1200,
      height: 1000,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/projects/projects_flick_01.png?as=jpeg&width=1200',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        660: new URL(
          '../assets/images/projects/projects_flick_01.png?as=avif&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_flick_01.png?as=avif&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_flick_01.png?as=avif&width=1200',
          import.meta.url,
        ),
      },
      webp: {
        660: new URL(
          '../assets/images/projects/projects_flick_01.png?as=webp&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_flick_01.png?as=webp&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_flick_01.png?as=webp&width=1200',
          import.meta.url,
        ),
      },
      jpeg: {
        660: new URL(
          '../assets/images/projects/projects_flick_01.png?as=jpeg&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_flick_01.png?as=jpeg&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_flick_01.png?as=jpeg&width=1200',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 1200px) 100vw, 1200px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  projects_flick_02: {
    img: {
      alt: 'test test',
      width: 1200,
      height: 1000,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/projects/projects_flick_02.png?as=jpeg&width=1200',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        660: new URL(
          '../assets/images/projects/projects_flick_02.png?as=avif&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_flick_02.png?as=avif&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_flick_02.png?as=avif&width=1200',
          import.meta.url,
        ),
      },
      webp: {
        660: new URL(
          '../assets/images/projects/projects_flick_02.png?as=webp&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_flick_02.png?as=webp&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_flick_02.png?as=webp&width=1200',
          import.meta.url,
        ),
      },
      jpeg: {
        660: new URL(
          '../assets/images/projects/projects_flick_02.png?as=jpeg&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_flick_02.png?as=jpeg&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_flick_02.png?as=jpeg&width=1200',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 1200px) 100vw, 1200px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  projects_mariza_01: {
    img: {
      alt: 'test test',
      width: 1200,
      height: 1000,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/projects/projects_mariza_01.png?as=jpeg&width=1200',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        660: new URL(
          '../assets/images/projects/projects_mariza_01.png?as=avif&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_mariza_01.png?as=avif&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_mariza_01.png?as=avif&width=1200',
          import.meta.url,
        ),
      },
      webp: {
        660: new URL(
          '../assets/images/projects/projects_mariza_01.png?as=webp&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_mariza_01.png?as=webp&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_mariza_01.png?as=webp&width=1200',
          import.meta.url,
        ),
      },
      jpeg: {
        660: new URL(
          '../assets/images/projects/projects_mariza_01.png?as=jpeg&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_mariza_01.png?as=jpeg&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_mariza_01.png?as=jpeg&width=1200',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 1200px) 100vw, 1200px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  projects_mariza_02: {
    img: {
      alt: 'test test',
      width: 1200,
      height: 1000,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/projects/projects_mariza_02.png?as=jpeg&width=1200',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        660: new URL(
          '../assets/images/projects/projects_mariza_02.png?as=avif&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_mariza_02.png?as=avif&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_mariza_02.png?as=avif&width=1200',
          import.meta.url,
        ),
      },
      webp: {
        660: new URL(
          '../assets/images/projects/projects_mariza_02.png?as=webp&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_mariza_02.png?as=webp&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_mariza_02.png?as=webp&width=1200',
          import.meta.url,
        ),
      },
      jpeg: {
        660: new URL(
          '../assets/images/projects/projects_mariza_02.png?as=jpeg&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_mariza_02.png?as=jpeg&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_mariza_02.png?as=jpeg&width=1200',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 1200px) 100vw, 1200px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  projects_creditor_01: {
    img: {
      alt: 'test test',
      width: 1200,
      height: 1000,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/projects/projects_creditor_01.png?as=jpeg&width=1200',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        660: new URL(
          '../assets/images/projects/projects_creditor_01.png?as=avif&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_creditor_01.png?as=avif&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_creditor_01.png?as=avif&width=1200',
          import.meta.url,
        ),
      },
      webp: {
        660: new URL(
          '../assets/images/projects/projects_creditor_01.png?as=webp&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_creditor_01.png?as=webp&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_creditor_01.png?as=webp&width=1200',
          import.meta.url,
        ),
      },
      jpeg: {
        660: new URL(
          '../assets/images/projects/projects_creditor_01.png?as=jpeg&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_creditor_01.png?as=jpeg&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_creditor_01.png?as=jpeg&width=1200',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 1200px) 100vw, 1200px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  projects_creditor_02: {
    img: {
      alt: 'test test',
      width: 1200,
      height: 1000,
      loading: 'eager',
      decoding: 'auto',
      src: new URL(
        '../assets/images/projects/projects_creditor_02.png?as=jpeg&width=1200',
        import.meta.url,
      ),
    },
    source: {
      avif: {
        660: new URL(
          '../assets/images/projects/projects_creditor_02.png?as=avif&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_creditor_02.png?as=avif&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_creditor_02.png?as=avif&width=1200',
          import.meta.url,
        ),
      },
      webp: {
        660: new URL(
          '../assets/images/projects/projects_creditor_02.png?as=webp&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_creditor_02.png?as=webp&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_creditor_02.png?as=webp&width=1200',
          import.meta.url,
        ),
      },
      jpeg: {
        660: new URL(
          '../assets/images/projects/projects_creditor_02.png?as=jpeg&width=660',
          import.meta.url,
        ),
        920: new URL(
          '../assets/images/projects/projects_creditor_02.png?as=jpeg&width=920',
          import.meta.url,
        ),
        1200: new URL(
          '../assets/images/projects/projects_creditor_02.png?as=jpeg&width=1200',
          import.meta.url,
        ),
      },
      sizes: '(max-width: 1200px) 100vw, 1200px',
    },
    background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
};

export default imagesData;
