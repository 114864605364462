const gsapKill = (timeline) => {
  let target = timeline;

  if (target) {
    target.kill();
    target = null;
  }

  return target;
};

export default gsapKill;
