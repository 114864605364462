import Lenis from '@studio-freight/lenis';
// import { gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
//
// gsap.registerPlugin(ScrollTrigger);

const scrolling = {
  lenis: null,
  smooth: {
    create() {
      this.lenis = new Lenis({
        wheelMultiplier: 0.75,
        normalizeWheel: true,
        duration: 0.8,
        easing: (t) => 1 - (1 - t) ** 3,
        // lerp: 0.08,
      });

      const raf = (time) => {
        if (!this.lenis) return;

        this.lenis.raf(time);
        requestAnimationFrame(raf);
      };

      requestAnimationFrame(raf);

      // this.lenis.on('scroll', ScrollTrigger.update);
      //
      // gsap.ticker.lagSmoothing(0);
    },
    destroy() {
      if (!this.lenis) return;

      this.lenis.destroy();
      this.lenis = null;
    },
    start() {
      if (!this.lenis) return;

      this.lenis.start();
    },
    stop() {
      if (!this.lenis) return;

      this.lenis.stop();
    },
  },
};

export default scrolling;
