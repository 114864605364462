<script>
import { ref, onMounted } from 'vue';

export default {
  name: 'CommonName',
  setup() {
    const currentTime = ref('');

    const formatTime = (date) => {
      const options = {
        timeZone: 'Europe/Moscow',
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
      };

      return date.toLocaleTimeString('en-US', options);
    };

    onMounted(() => {
      const getCurrentTime = () => {
        const date = new Date();
        currentTime.value = formatTime(date);
      };

      getCurrentTime();

      setInterval(getCurrentTime, 1000);
    });

    return {
      currentTime,
    };
  },
};
</script>

<template>
  <div class="common common--name">
    <div class="body-medium">Веб-разработчик</div>

    <div class="common__body">
      <AppLink
        class="link--common"
        :throttle="1000"
        :changed="1000"
        to="/"
      >
        Максим Ефремов
      </AppLink>

      <span class="displace">GMT+3 ({{ currentTime }}, RU)</span>
    </div>
  </div>
</template>
