import {
  computed, onMounted, watchEffect, watch,
} from 'vue';
import { useRoute } from 'vue-router';
import useLoading from '../store/loading';

const bodyClass = () => {
  const route = useRoute();
  const loading = useLoading();

  const normalizePath = (path) => path.replace(/\//g, '-').slice(1);

  const pageStyling = computed(() => (route.path === '/'
    ? 'is-page-home'
    : `is-page-${normalizePath(route.path)
    }`));

  const updateBodyClass = (newClass, oldClass) => {
    const { body } = document;

    if (oldClass) body.classList.remove(oldClass);

    body.classList.add(newClass);
  };

  watchEffect(() => {
    if (loading.complete) {
      document.body.classList.add('page-loaded');
    }
  });

  watch(pageStyling, updateBodyClass, { immediate: true });

  onMounted(() => {
    updateBodyClass(pageStyling.value);
  });
};

export default bodyClass;
