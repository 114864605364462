<script>
import {
  onBeforeUnmount, onMounted, ref, watch,
} from 'vue';
import gsap from 'gsap';
import useLoading from '../store/loading';
import useMedia from '../store/media';
import gsapKill from '../utils/gsapKill';
import CommonName from '../components/CommonName.vue';
import CommonStatus from '../components/CommonStatus.vue';
import CommonNav from '../components/CommonNav.vue';
import CommonConnect from '../components/CommonConnect.vue';

export default {
  name: 'LayoutHeader',
  components: {
    CommonName,
    CommonStatus,
    CommonNav,
    CommonConnect,
  },
  setup() {
    const headerRef = ref();
    const loading = useLoading();
    const media = useMedia();

    const tlHeaderLoaded = gsap.timeline({ paused: true });

    const animation = () => {
      const headerItems = gsap.utils.toArray(headerRef.value.children);

      tlHeaderLoaded
        .fromTo(headerItems, {
          autoAlpha: 0,
          x: -50,
        }, {
          autoAlpha: 1,
          x: 0,
          duration: 1.5,
          ease: 'expo.out',
          stagger: {
            amount: 0.4,
          },
        })
        .fromTo(headerRef.value, {
          '--header-border-width': '0%',
        }, {
          duration: 1,
          ease: 'power3.out',
          '--header-border-width': '100%',
        }, '<');
    };

    const memoizedAnimation = ref(animation);

    watch(() => loading.complete, (complete) => {
      if (complete) {
        tlHeaderLoaded.restart();
      } else {
        tlHeaderLoaded.seek(0).pause();
      }
    });

    onMounted(memoizedAnimation.value);

    onBeforeUnmount(() => {
      gsapKill(tlHeaderLoaded);
    });

    return {
      headerRef,
      media,
    };
  },
};
</script>

<template>
  <header
    class="header"
    ref="headerRef"
    data-cursor-style="none"
  >
    <CommonName />
    <CommonStatus />
    <CommonNav v-if="media.phone" />
    <CommonConnect v-if="media.phone" />
  </header>
</template>

<style lang="scss">
@import "../assets/scss/base/mixins";

.header {
  position: relative;
  padding: 12px var(--horizontal-offset);

  @include max-width(tablet-landscape) {
    display: flex;
    justify-content: space-between;
  }

  @include min-width(tablet-landscape) {
    display: grid;
    grid-template: 1fr / 1fr 1fr 1fr 1fr;
    gap: var(--glob-gap);
  }

  > * {
    display: grid;
    align-self: center;
  }

  @include min-width(tablet-portrait) {
    padding: 0 var(--horizontal-offset);
  }

  &::after {
    content: '';
    position: absolute;
    inset: auto 0 0 var(--horizontal-offset);

    @include border-side(bottom);

    width: calc(var(--header-border-width, 100%) - (var(--horizontal-offset) * 2));
    height: 1px;
    transform-origin: center right;
  }

  .displace,
  .link {
    @include max-width(tablet-portrait) {
      line-height: unset;
      height: auto;
    }
  }
}
</style>
