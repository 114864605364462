import scrolling from './lenis';
import Timeout from './Timeout';

const appPreloader = document.querySelector('.app-preloader');

const preloader = async () => {
  scrolling.smooth.stop();

  await Timeout.animation(2000);

  document.body.classList.add('char-done');

  await Timeout.animation(400);

  document.body.classList.add('circle-done');

  await Timeout.animation(800);

  document.body.classList.remove('char-done', 'circle-done');

  appPreloader.remove();

  scrolling.smooth.start();
};

export default preloader;
