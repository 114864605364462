<script>
export default {
  name: 'CommonNav',
};
</script>

<template>
  <div class="common common--menu">
    <div class="body-medium">Меню</div>

    <div class="common__body">
      <AppLink
        class="link--common"
        :throttle="1000"
        :changed="1000"
        to="/"
      >
        Обо мне
      </AppLink>
      <AppLink
        class="link--common"
        :throttle="1000"
        :changed="1000"
        to="/projects"
      >
        Проекты [7]
      </AppLink>
<!--      <AppLink-->
<!--        class="link&#45;&#45;common"-->
<!--        style="pointer-events: none; opacity: 0.5;"-->
<!--        :throttle="1000"-->
<!--        :changed="1000"-->
<!--        to="/blog"-->
<!--      >-->
<!--        Блог-->
<!--      </AppLink>-->
      <!--      <AppLink-->
      <!--        class="link&#45;&#45;common"-->
      <!--        :throttle="1000"-->
      <!--        :changed="1000"-->
      <!--        to="/about"-->
      <!--      >-->
      <!--        Обо мне-->
      <!--      </AppLink>-->
      <AppLink
        class="link--common"
        :throttle="1000"
        :changed="1000"
        to="/contact"
      >
        Контакты
      </AppLink>
    </div>
  </div>
</template>
