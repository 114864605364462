class CursorBlob {
  static gsap;

  #cursor;

  #cursorRim;

  #cursorDot;

  #pos;

  #vel;

  #animation;

  #cursorDotFactor;

  #boundSetFromEvent;

  constructor({
    cursorEl = '.cursor',
    cursorRimEl = '.cursor-rim',
    cursorDotEl = '.cursor-dot',
  } = {}) {
    this.gsap = CursorBlob.gsap || window.gsap;

    const getElement = (el) => (el instanceof HTMLElement ? el : document.querySelector(el));

    this.#cursor = getElement(cursorEl);
    this.#cursorRim = getElement(cursorRimEl);
    this.#cursorDot = getElement(cursorDotEl);

    this.#pos = { x: 0, y: 0 };
    this.#vel = { x: 0, y: 0 };
    this.#animation = null;
    this.#cursorDotFactor = 0.1;

    this.#boundSetFromEvent = this.#setFromEvent.bind(this);

    if (this.#cursor) {
      window.addEventListener('pointermove', this.#boundSetFromEvent);
    }
  }

  static registerGSAP(gsap) {
    CursorBlob.gsap = gsap;
  }

  static setProperty(el, properties) {
    this.gsap.set(el, properties);
  }

  static #getScale(diffX, diffY) {
    const distance = Math.hypot(diffX, diffY);
    return Math.min(distance / 485, 0.35);
  }

  static #getAngle(diffX, diffY) {
    return (Math.atan2(diffY, diffX) * 180) / Math.PI;
  }

  #loop() {
    const { x, y } = this.#pos;
    const { x: velX, y: velY } = this.#vel;

    const rotation = CursorBlob.#getAngle(velX, velY);
    const scale = CursorBlob.#getScale(velX, velY);

    CursorBlob.setProperty(this.#cursorRim, {
      x, y, rotate: rotation, scaleX: 1 + scale, scaleY: 1 - scale
    });

    CursorBlob.setProperty(this.#cursorDot, {
      x: x + velX * this.#cursorDotFactor, y: y + velY * this.#cursorDotFactor
    });
  }

  #clearTimeline() {
    this.gsap.killTweensOf(this.#pos);

    if (this.#animation) {
      this.#animation.kill();
      this.#animation = null;
    }
  }

  #setFromEvent(e) {
    const { clientX: x, clientY: y } = e;
    const cursorWidth = this.#cursorRim.offsetWidth;
    const cursorHeight = this.#cursorRim.offsetHeight;
    const offsetXCursor = cursorWidth / 2;
    const offsetYCursor = cursorHeight / 2;

    this.#clearTimeline();

    this.#animation = this.gsap.to(this.#pos, {
      x: x - offsetXCursor,
      y: y - offsetYCursor,
      duration: 0.95,
      ease: 'Expo.easeOut',
      onUpdate: () => {
        this.#vel.x = x - offsetXCursor - this.#pos.x;
        this.#vel.y = y - offsetYCursor - this.#pos.y;
        this.#loop();
        this.#style(e);
      },
    });
  }

  #style(e) {
    let { target } = e;

    while (target && (!target.dataset || !target.dataset.cursorStyle) && target !== document.body) {
      target = target.parentNode;
    }

    if (this.#cursor) {
      const cursorStyle = (target && target.dataset && target.dataset.cursorStyle) || 'default';

      this.#cursor.className = 'cursor';
      this.#cursor.classList.add(`cursor--${cursorStyle}`);
    }
  }

  destroy() {
    if (this.#cursor) {
      window.removeEventListener('pointermove', this.#boundSetFromEvent);

      this.#clearTimeline();
    }
  }
}

export default CursorBlob;
