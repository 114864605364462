<script>
import { ref, onMounted } from 'vue';
import useMedia from '../store/media';

export default {
  name: 'CommonConnect',
  setup() {
    const media = useMedia();
    const randomGuesses = ref([0, 0, 0]);

    const randomizeGuesses = () => {
      setInterval(() => {
        randomGuesses.value = randomGuesses.value.map(() => Math.floor(Math.random() * 10));
      }, 50);
    };

    onMounted(() => {
      randomizeGuesses();
    });

    return {
      media,
      randomGuesses,
    };
  },
};
</script>

<template>
  <div class="common common--connect">
    <div class="body-medium">Связь</div>

    <div class="common__body">
      <a class="link link--common" target="_blank" href="https://t.me/ace_coder">Телеграм</a>
      <a class="link link--common" href="mailto:max@ux-ui.pro">@-Email</a>
      <span class="displace">
        <template v-if="media.touch">
          +7 (<span class="random-guesses" v-for="(guess, i) in randomGuesses" :key="i">{{ randomGuesses[i] }}
        </span>) 565 66 66</template>
        <template v-else>Телефон</template>
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.random-guesses {
  color: var(--c-wet-asphalt);

  //color: transparent;
  //text-shadow: 0 0 1px var(--c-wet-asphalt);
}
</style>
