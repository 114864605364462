class Timeout {
  static delay = (ms) => new Promise((resolve) => { setTimeout(resolve, ms) });

  static animation = (ms) => new Promise((resolve) => {
    const start = performance.now();
    const wait = () => {
      if (performance.now() - start < ms) {
        requestAnimationFrame(wait);
      } else {
        resolve();
      }
    };

    requestAnimationFrame(wait);
  });
}

export default Timeout;
